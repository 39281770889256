import React, { useEffect, useRef, useState } from 'react';
import { Element, Events, animateScroll as scroll, scroller, scrollSpy } from 'react-scroll';
import './index.css';
import { About, Pricing, Features, Landing, Header } from './components';


const App = () => {
  const emailInputRef = useRef(null);
  const [clickedPlan, setClickedPlan] = useState('');

  useEffect(() => {
    Events.scrollEvent.register('begin', function () {
      // console.log("begin", arguments);
    });

    Events.scrollEvent.register('end', function () {
      // console.log("end", arguments);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  const handleHeaderClick = () => {
    scroller.scrollTo('pricing', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  }

  const focusEmailInput = () => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  };

  return (
    <div className="flex flex-col items-center overflow-x-hidden">
      <Header handleHeaderClick={handleHeaderClick} />
      <Element name="landing" className="min-h-screen flex justify-center items-center overflow-hidden w-full">
        <Landing emailRef={emailInputRef} clickedPlan={clickedPlan} />
      </Element>
      <Element name="features" className="min-h-screen flex justify-center items-center bg-neutral-900 w-full">
        <Features />
      </Element>
      <Element name="pricing" className="min-h-screen flex justify-center items-center">
        <Pricing />
      </Element>
      <Element name="about" className="min-h-screen flex justify-center items-center bg-neutral-900 w-full">
        <About />
      </Element>
    </div>
  );
};

export default App;
