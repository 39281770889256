import React from 'react';

export const Pricing = () => {
  const originalPrices = {
    starter: 95,
    core: 288,
    business: 480,
  };

  const discountedPrices = {
    starter: (95 * 0.7).toFixed(2),
    core: (288 * 0.7).toFixed(2),
    business: (480 * 0.7).toFixed(2),
  };

  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">Pricing</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Choose the plan that's right for you
          </p>
        </div>

        <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Starter Tier */}
          <div className="relative max-w-sm mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="absolute top-0 right-0 bg-red-600 text-white text-xs font-bold px-2 py-1 rounded-bl-lg">
              30% off for LIFE!
            </div>
            <div className="px-6 py-8">
              <h3 className="text-center text-xl font-bold text-gray-900">Starter</h3>
              <p className="mt-4 text-center text-4xl font-extrabold text-gray-900">
                <span className="line-through">${originalPrices.starter}</span>
                <span className="text-red-600"> ${discountedPrices.starter}</span>
                <span className="text-base font-medium text-gray-500">/month</span>
              </p>
              <p className="mt-4 text-center text-gray-600 text-sm">Best for teams testing a simple AI app</p>
              <ul className="mt-6 space-y-4">
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 text-green-500">&#10003;</span>
                  <p className="ml-3 text-gray-700">Track AI agent performance</p>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 text-green-500">&#10003;</span>
                  <p className="ml-3 text-gray-700">Monitor actions taken by agent</p>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 text-green-500">&#10003;</span>
                  <p className="ml-3 text-gray-700">Manage costs with each agent</p>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 text-green-500">&#10003;</span>
                  <p className="ml-3 text-gray-700">Error logging with in-app alerts</p>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 text-green-500">&#10003;</span>
                  <p className="ml-3 text-gray-700">Watch monthly spend w/ budget projection</p>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 text-green-500">&#10003;</span>
                  <p className="ml-3 text-gray-700">Download reports on AI performance and cost metrics</p>
                </li>
              </ul>
            </div>
            <a href={'https://buy.stripe.com/14keY47pIf9h18sdQU'} className="cursor-pointer max-w-36 flex justify-center items-center mx-auto bg-gray-800 text-white text-sm font-semibold py-3 px-4 mb-4 rounded-md transition ease-in-out duration-75 hover:bg-white hover:border-2 hover:mb-[12px] hover:border-black hover:text-neutral-900">Pre-order Now</a>
          </div>

          {/* Core Tier */}
          <div className="relative max-w-sm mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="absolute top-0 right-0 bg-red-600 text-white text-xs font-bold px-2 py-1 rounded-bl-lg">
              30% off for LIFE!
            </div>
            <div className="px-6 py-8">
              <h3 className="text-center text-xl font-bold text-gray-900">Core</h3>
              <p className="mt-4 text-center text-4xl font-extrabold text-gray-900">
                <span className="line-through">${originalPrices.core}</span>
                <span className="text-red-600"> ${discountedPrices.core}</span>
                <span className="text-base font-medium text-gray-500">/month</span>
              </p>
              <p className="mt-4 text-center text-gray-600 text-sm">Best for launching one or more AI app(s)</p>
              <ul className="mt-6 space-y-4">
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 text-green-500">&#10003;</span>
                  <p className="ml-3 text-gray-700">Advanced error analysis</p>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 text-green-500">&#10003;</span>
                  <p className="ml-3 text-gray-700">Customizable alert thresholds</p>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 text-green-500">&#10003;</span>
                  <p className="ml-3 text-gray-700">Customizable error types</p>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 text-green-500">&#10003;</span>
                  <p className="ml-3 text-gray-700">Integration with third-party reporting tools</p>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 text-green-500">&#10003;</span>
                  <p className="ml-3 text-gray-700">Email, Slack, SMS error alerts</p>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 text-green-500">&#10003;</span>
                  <p className="ml-3 text-gray-700">Visual breakdown of monthly spend by agent</p>
                </li>
              </ul>
            </div>
            <a href={'https://buy.stripe.com/bIY7vCaBUe5dg3m28d'} className="cursor-pointer max-w-36 flex justify-center items-center mx-auto bg-gray-800 text-white text-sm font-semibold py-3 px-4 mb-4 rounded-md transition ease-in-out duration-75 hover:bg-white hover:border-2 hover:mb-[12px] hover:border-black hover:text-neutral-900">Pre-order Now</a>
          </div>

          {/* Business Tier */}
          <div className="relative max-w-sm mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="absolute top-0 right-0 bg-red-600 text-white text-xs font-bold px-2 py-1 rounded-bl-lg">
              30% off for LIFE!
            </div>
            <div className="px-6 py-8">
              <h3 className="text-center text-xl font-bold text-gray-900">Business</h3>
              <p className="mt-4 text-center text-4xl font-extrabold text-gray-900">
                <span className="line-through">${originalPrices.business}</span>
                <span className="text-red-600"> ${discountedPrices.business}</span>
                <span className="text-base font-medium text-gray-500">/month</span>
              </p>
              <p className="mt-4 text-center text-gray-600 text-sm">Best for scaling AI apps with advanced features</p>
              <ul className="mt-6 space-y-4">
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 text-green-500">&#10003;</span>
                  <p className="ml-3 text-gray-700">AI-driven error resolution suggestions</p>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 text-green-500">&#10003;</span>
                  <p className="ml-3 text-gray-700">Root cause analysis for errors</p>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 text-green-500">&#10003;</span>
                  <p className="ml-3 text-gray-700">Deep dive into token usage trends & patterns</p>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 text-green-500">&#10003;</span>
                  <p className="ml-3 text-gray-700">Predictive analytics for token usage & cost forecasting</p>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 text-green-500">&#10003;</span>
                  <p className="ml-3 text-gray-700">Priority support</p>
                </li>
                <li className="flex items-start">
                  <span className="flex-shrink-0 h-6 w-6 text-green-500">&#10003;</span>
                  <p className="ml-3 text-gray-700">Custom integrations on request</p>
                </li>
              </ul>
            </div>
            <a href='https://buy.stripe.com/00gcPWeSaaT1g3maEK' className="cursor-pointer max-w-36 flex justify-center items-center mx-auto bg-gray-800 text-white text-sm font-semibold py-3 px-4 mb-4 rounded-md transition ease-in-out duration-75 hover:bg-white hover:border-2 hover:mb-[12px] hover:border-black hover:text-neutral-900">Pre-order Now</a>
          </div>
        </div>
      </div>
    </div>
  );
};
