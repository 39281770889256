import React, { useState, useEffect, useRef } from 'react';
import dashboard from '../assets/images/dashboard.jpeg';
import './Landing.css';
import { Link } from 'react-scroll';

export const Landing = ({ emailRef, clickedPlan }) => {
  const [email, setEmail] = useState('');
  const [alert, setAlert] = useState('');
  const [waitlistCount, setWaitlistCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const titleRef = useRef(null);
  const subTitleRef = useRef(null);
  const dashboardRef = useRef(null);
  const orbRef = useRef(null);

  useEffect(() => {
    const animateElement = (element, animationClass, delay) => {
      if (element) {
        setTimeout(() => {
          element.classList.add(animationClass);
        }, delay);
      }
    };

    animateElement(titleRef.current, 'fadeInUp', 0);
    animateElement(subTitleRef.current, 'fadeInUp', 500);
    animateElement(dashboardRef.current, 'fadeInUp', 1000);
    animateElement(orbRef.current, 'fadeIn', 1500);
  }, []);

  useEffect(() => {
    const orb = document.getElementById('orb');
    let x = 0;
    let y = 0;
    let directionX = 1;
    let directionY = 1;

    const animateOrb = () => {
      const maxX = window.innerWidth / 4;
      const maxY = 100;

      if (x >= maxX || x <= -maxX) directionX *= -1;
      if (y >= maxY || y <= -maxY) directionY *= -1;

      x += directionX * 0.5;
      y += directionY * 0.5;

      orb.style.transform = `translate(${x}px, ${y}px)`;

      requestAnimationFrame(animateOrb);
    };

    requestAnimationFrame(animateOrb);
  }, []);

  useEffect(() => {
    const fetchWaitlistCount = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_GOOGLE_WEB_APP_URL}?getCount=true`);
        const data = await response.json();
        setWaitlistCount(data.count);
      } catch (error) {
        console.error('Error fetching waitlist count:', error);
      }
    };

    fetchWaitlistCount();
  }, []);

  const getSpotsAvailable = (num) => {
    const targets = [25, 50, 100, 150, 200, 250, 300];
    
    for (let i = 350; i <= Math.ceil(num / 50) * 50; i += 50) {
      targets.push(i);
    }
    
    for (const target of targets) {
      if (num <= target) {
        return target;
      }
    }
    
    return Math.ceil(num / 50) * 50;
  };

  const handleSubmit = async () => {
    if (email) {
      setLoading(true);
      try {
        const response = await fetch(process.env.REACT_APP_GOOGLE_WEB_APP_URL, {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, plan: clickedPlan }),
        });

        setAlert('Your email has been submitted successfully!');
        setEmail('');
      } catch (error) {
        setAlert('There was an error submitting your email.');
      } finally {
        setLoading(false);
      }
    } else {
      setAlert('Please enter your email.');
    }

    setTimeout(() => setAlert(''), 5000);
  };

  return (
    <div className="relative flex flex-col h-full items-center mt-24 px-6">
      {alert && (
        <div className="absolute top-0 mt-4 w-full max-w-md mx-auto bg-green-500 text-white text-center py-2 rounded-lg shadow-md z-10">
          {alert}
        </div>
      )}
      <div id="orb" ref={orbRef} className="absolute w-48 h-48 md:w-[500px] md:h-[500px] bottom-60 transform -translate-x-1/2 bg-radial-gradient from-blue-500 to-blue-400 opacity-30 blur-3xl rounded-full -z-10 opacity-0"></div>
      <div className="text-center space-y-4 md:space-y-6 md:mt-6">
        <h1 ref={titleRef} className="mx-auto text-4xl md:text-6xl max-w-4xl font-bold leading-tight opacity-0">
          Bring <span className="bg-gradient-to-r from-blue-700 to-indigo-600 inline-block text-transparent bg-clip-text">peace of mind</span><br/> to your AI solutions
        </h1>
        <p ref={subTitleRef} className="text-lg md:text-xl opacity-0">Ensure reliable performance, catch errors, control costs.</p>
      </div>
      <div className="relative flex flex-col items-center my-6 space-y-2 w-full max-w-md md:max-w-xl">
        <div className="bg-gradient-to-br from-slate-800 to-slate-600 text-neutral-200 font-semibold text-center py-3 px-4 md:px-6 rounded-full border border-slate-600 shadow-md">
          Pre-order now and get <span className='mx-1 px-2 py-1 text-lg text-red-600 border-4 border-red-600 bg-white rounded-xl'>30%</span> off for <span className="bg-gradient-to-r from-rose-400 to-red-400 inline-block text-transparent bg-clip-text font-bold">LIFE</span>!
        </div>
        <p className="text-neutral-500 font-semibold text-center text-sm md:text-base">{waitlistCount}/{getSpotsAvailable(waitlistCount)} early access spots taken</p>
        <Link
          to={'pricing'}
          className="cursor-pointer px-4 py-3 md:px-5 md:py-3 bg-green-600 text-white rounded-xl shadow-md font-semibold w-full md:w-auto hover:bg-green-500 transition ease-in-out duration-100 text-center"
        >
          Pre-order Now
        </Link>
        <p className="text-center text-sm text-gray-700 mt-2">
          Join now to shape the product to your needs!
        </p>
      </div>
      <div ref={dashboardRef} className="flex justify-center w-full mt-auto opacity-0">
        <img src={dashboard} alt="Dashboard" className="rounded-t-lg rounded-b-lg border-b-2 md:border-b-0 md:rounded-b-none shadow-lg border-t-2 border-x-2 border-neutral-200 w-full max-w-4xl object-contain" />
      </div>
    </div>
  );
};
