import React from 'react';

export const About = () => {
  return (
    <div className="py-12 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base my-5 font-semibold text-indigo-500 tracking-wide uppercase">About Us</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
            Our Mission
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-300 lg:mx-auto">
            Turret’s mission is to mitigate the risks in adopting AI into your business or product. Whether that’s a customer-facing feature with AI, or an internal tool to streamline productivity, you’ll be prepared for anything.
          </p>
        </div>
        <div className="mt-10 max-w-4xl mx-auto text-lg text-gray-400 space-y-6">
          <p>
            Every week, thousands of AI products are launched, making their founders money on autopilot, or built internally, promoting the 10x developers who built them.
          </p>
          <p>
            But LLMs make mistakes. In some use-cases, it’s reliable, but needs a human-in-the-loop. In others, the models need more work to be reliable. Regardless, as these models get better, you no longer need to manually manage AI activity, errors, and costs for the AI app you’re building.
          </p>
        </div>
      </div>
    </div>
  );
};
