import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import turret_icon from '../assets/images/turret_icon.png';

export const Header = ({ handleHeaderClick }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav
      className={`fixed justify-center top-0 px-4 md:px-8 py-3 transition-all duration-300 z-40 ${
        isScrolled ? `bg-white bg-opacity-80 backdrop-blur-md rounded-lg shadow-md mt-2 w-[98%] mx-auto` : 'bg-white mx-auto w-full'
      }`}
    >
      <div className="flex items-center justify-between">
        <Link to="landing" className="inline-flex items-center space-x-2 cursor-pointer hover:bg-neutral-100 rounded-md p-2 transition ease-in-out duration-150">
          <img src={turret_icon} alt="Turret" className="w-8 object-contain" />
          <p className="text-xl md:text-2xl font-bold pr-1">Turret</p>
        </Link>
        <div className="md:hidden flex items-center">
          <button
            className="focus:outline-none"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <svg
              className="w-6 h-6 text-gray-800"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
        <ul className="hidden md:flex space-x-5">
          {['Features', 'Pricing', 'About'].map((item) => (
            <li key={item}>
              <Link
                activeClass="text-neutral-900 bg-neutral-200 font-semibold"
                className="px-3 py-2 text-neutral-600 rounded-md hover:bg-neutral-200 hover:cursor-pointer transition-all ease-in-out duration-100"
                to={item.toLowerCase()}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                {item}
              </Link>
            </li>
          ))}
        </ul>
        <div className="hidden md:inline-flex space-x-4">
          <button onClick={handleHeaderClick} className="px-4 py-2 md:px-5 md:py-3 bg-blue-600 text-white text-sm rounded-2xl shadow-md font-semibold hover:bg-blue-500 transition ease-in-out duration-200">
            Get My 30% Discount
          </button>
        </div>
      </div>
      {menuOpen && (
        <div className="md:hidden mt-4 space-y-2">
          {['Features', 'Pricing', 'About'].map((item) => (
            <Link
              key={item}
              className="block px-3 py-2 text-neutral-600 rounded-md hover:bg-neutral-200 hover:cursor-pointer transition-all ease-in-out duration-100"
              to={item.toLowerCase()}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => setMenuOpen(false)}
            >
              {item}
            </Link>
          ))}
          <button onClick={handleHeaderClick} className="block w-full px-4 py-4 bg-blue-600 text-white text-sm rounded-2xl shadow-md font-semibold hover:bg-blue-500 transition ease-in-out duration-200">
            Get My 30% Discount
          </button>
        </div>
      )}
    </nav>
  );
};
