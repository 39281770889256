import React, { useEffect, useRef } from 'react';
import monitor_icon from '../assets/images/monitor_icon.png';
import cost_icon from '../assets/images/cost_icon.png';
import error_icon from '../assets/images/error_icon.png';
import alert_icon from '../assets/images/alert_icon.png';
import report_icon from '../assets/images/report_icon.png';
import budget_icon from '../assets/images/budget_icon.png';
import monitor_display from '../assets/images/monitor_display.png';
import cost_display from '../assets/images/cost_display.png';
import error_display from '../assets/images/error_display.png';
import alert_display from '../assets/images/alert_display.png';
import report_display from '../assets/images/report_display.png';
import budget_display from '../assets/images/budget_display.png';
import './Features.css';

export const Features = () => {
  const titleRef = useRef(null);
  const subTitleRef = useRef(null);
  const firstRowRef = useRef(null);
  const secondRowRef = useRef(null);
  const sectionRef = useRef(null);

  useEffect(() => {
    const options = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const animateElement = (element, animationClass, delay) => {
            if (element) {
              setTimeout(() => {
                element.classList.add(animationClass);
              }, delay);
            }
          };

          animateElement(titleRef.current, 'fadeInUp', 0);
          animateElement(subTitleRef.current, 'fadeInUp', 500);
          animateElement(firstRowRef.current, 'fadeInUp', 1000);
          animateElement(secondRowRef.current, 'fadeInUp', 1500);

          observer.unobserve(entry.target); // Stop observing after animation is triggered
        }
      });
    }, options);

    observer.observe(sectionRef.current);

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div ref={sectionRef} className="relative flex flex-col space-y-8 px-6 py-12 md:px-24">
      <div className="absolute w-[500px] h-[500px] md:w-[500px] md:h-[500px] bottom-1/2 md:bottom-60 left-8 md:left-1/3 bg-radial-gradient from-blue-500 to-blue-400 opacity-20 blur-3xl rounded-full z-10"></div>
      <div className="space-y-4 z-20">
        <h1 ref={titleRef} className="text-3xl md:text-4xl font-bold text-center text-neutral-100 opacity-0">
          <span className="bg-gradient-to-r from-blue-500 to-indigo-200 inline-block text-transparent bg-clip-text">Transform</span> LLM uncertainty into stability.
        </h1>
        <p ref={subTitleRef} className="text-center text-neutral-300 text-lg md:text-base max-w-xl mx-auto opacity-0">
          Turret provides a suite of tools to help you manage your AI agents.
          <br />
          Simply implement our SDK & give yourself omniscience over your AI solutions.
        </p>
      </div>
      <div className="space-y-3">
        <div ref={firstRowRef} className="grid grid-cols-1 md:grid-cols-3 gap-5 z-10 relative opacity-0">
          <div className="p-4 flex flex-col justify-between rounded-lg bg-neutral-800 bg-opacity-50 backdrop-blur-2xl border-2 border-neutral-700 text-neutral-50">
            <img src={monitor_display} alt="Monitor Display" className="mx-auto w-52 object-contain mb-4" />
            <div>
              <div className="inline-flex space-x-2">
                <img src={monitor_icon} alt="Monitor" className="w-5 object-contain" />
                <p className="font-bold text-sm">Live Monitoring</p>
              </div>
              <p className="text-sm">Never miss something your agent does; monitor LLM responses & actions live.</p>
            </div>
          </div>
          <div className="p-4 flex flex-col h-full justify-between rounded-lg bg-neutral-800 bg-opacity-50 backdrop-blur-2xl border-2 border-neutral-700 text-neutral-50">
            <img src={cost_display} alt="Cost Display" className="mx-auto mt-6 w-56 object-contain mb-4" />
            <div>
              <div className="inline-flex space-x-2 mt-auto">
                <img src={cost_icon} alt="Cost" className="w-4 object-contain" />
                <p className="font-bold text-sm">Cost Transparency</p>
              </div>
              <p className="text-sm">Track your language model usage. Never get a surprise bill from model providers.</p>
            </div>
          </div>
          <div className="p-4 flex flex-col justify-between rounded-lg bg-neutral-800 bg-opacity-50 backdrop-blur-2xl border-2 border-neutral-700 text-neutral-50">
            <img src={error_display} alt="Error Display" className="mx-auto mt-6 w-52 object-contain mb-4" />
            <div>
              <div className="inline-flex space-x-2">
                <img src={error_icon} alt="Error" className="w-4 object-contain" />
                <p className="font-bold text-sm">Error Detection</p>
              </div>
              <p className="text-sm">Catch AI agent errors when they happen, trace hallucinations, and view missed context.</p>
            </div>
          </div>
        </div>
        <div ref={secondRowRef} className="grid grid-cols-1 md:grid-cols-3 gap-5 z-20 opacity-0">
          <div className="p-4 flex flex-col justify-between rounded-lg bg-neutral-800 bg-opacity-50 backdrop-blur-2xl border-2 border-neutral-700 text-neutral-50">
            <img src={alert_display} alt="Alert Display" className="mt-6 mx-auto w-52 object-contain mb-4" />
            <div>
              <div className="inline-flex space-x-2">
                <img src={alert_icon} alt="Alert" className="w-5 object-contain" />
                <p className="font-bold text-sm">Stay Alert</p>
              </div>
              <p className="text-sm">Get alerts on usage, errors, and custom thresholds with higher plans.</p>
            </div>
          </div>
          <div className="p-4 flex flex-col justify-between rounded-lg bg-neutral-800 bg-opacity-50 backdrop-blur-2xl border-2 border-neutral-700 text-neutral-50">
            <img src={report_display} alt="Report Display" className="mx-auto mt-3 w-52 object-contain mb-4" />
            <div>
              <div className="inline-flex space-x-2">
                <img src={report_icon} alt="Report" className="w-5 object-contain" />
                <p className="font-bold text-sm">Generate Reports</p>
              </div>
              <p className="text-sm">Generate and download reports on AI agent performance and cost metrics.</p>
            </div>
          </div>
          <div className="p-4 flex flex-col justify-between rounded-lg bg-neutral-800 bg-opacity-50 backdrop-blur-2xl border-2 border-neutral-700 text-neutral-50">
            <img src={budget_display} alt="Budget Display" className="mx-auto mt-3 w-48 object-contain mb-4" />
            <div>
              <div className="inline-flex space-x-2">
                <img src={budget_icon} alt="Budget" className="w-5 object-contain" />
                <p className="font-bold text-sm">Budget Management</p>
              </div>
              <p className="text-sm">Monitor your monthly spend and project budgets to manage expenses.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};